(function($) {
  var Toolbox = {
    getCookie: function getCookie(name) {
                  var dc = document.cookie;
                  var prefix = name + "=";
                  var begin = dc.indexOf("; " + prefix);
                  if (begin == -1) {
                    begin = dc.indexOf(prefix);
                    if (begin != 0) return null;
                  }
                    else
                      {
                        begin += 2;
                        var end = document.cookie.indexOf(";", begin);
                        if (end == -1) {
                    end = dc.length;
                        }
                      }
                  return unescape(dc.substring(begin + prefix.length, end));
                },
    getCookie2: function getCookie2(cname) {
                  var name = cname + "=";
                  var ca = document.cookie.split(';');
                  for(var i=0; i<ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1);
                    if (c.indexOf(name) == 0) {  return c.substring(name.length,c.length); } //alert(c.substring(name.length,c.length));
                  }
                    
                  return "";

                } ,
    setCookie2: function setCookie2(cname, cvalue, exdays) {
                  var d = new Date();
                  d.setTime(d.getTime() + (exdays*24*60*60*1000));
                  var expires = "expires="+d.toUTCString();
                  document.cookie = cname + "=" + cvalue + "; " + expires + ";" + "path=/";
                } 


    
  };
  // Attach Toolbox to window
  window.Toolbox = Toolbox;
})(jQuery);

//SETTING COOKIE FUNCTIONS
 /*         getCookie=
          function getCookie(name) {
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1) {
              begin = dc.indexOf(prefix);
              if (begin != 0) return null;
            }
              else
                {
                  begin += 2;
                  var end = document.cookie.indexOf(";", begin);
                  if (end == -1) {
              end = dc.length;
                  }
                }
            return unescape(dc.substring(begin + prefix.length, end));
          } 

         getCookie2= 
         function getCookie2(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1);
              if (c.indexOf(name) == 0) {  return c.substring(name.length,c.length); } //alert(c.substring(name.length,c.length));
            }
              
            return "";

          } 

          //Set a cookie for checkout button on front end; display or not     
         setCookie2= 
         function setCookie2(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires + ";" + "path=/";
          } */
